@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@800&family=Poppins&family=Roboto&family=Ubuntu&display=swap');


*{
    font-family:'Poppins','Roboto', sans-serif ;
}

input{
    border-radius: 20px;
    border:2px black solid;
    height:3.2rem;
    padding:1rem;
}
input:focus{
    border: green solid 2px;
}